import LoadingScreen from 'components/loading-screen'
import { motion, useScroll, useSpring, useTransform } from 'framer-motion'
import usePreloadImages from 'hooks/use-preload-images'
import React from 'react'
import Fonts from 'styles/fonts'
import Global from 'styles/global'
import { Grid, ScrollContainer, Centered } from './styled'

const MotionGrid = motion(Grid)

const Showcase = () => {
  const isLoading = usePreloadImages()

  const { scrollYProgress } = useScroll()

  const spring = useSpring(scrollYProgress, { mass: 0.1, restDelta: 0.0001 })

  const rotate = useTransform(spring, progress => progress * 270 + 'deg')
  const inverseRotate = useTransform(
    spring,
    progress => -progress * 270 + 'deg'
  )
  const scale = useTransform(spring, progress => 1 + progress * 2)

  return (
    <>
      <Fonts />
      <Global />
      {isLoading && <LoadingScreen />}
      <ScrollContainer>
        <Centered>
          <MotionGrid style={{ rotate, scale }}>
            {[...Array(8)].map(i => (
              <li key={i}>
                <motion.img
                  src="/photos/shadows-of-lisbon/DSC09181.jpg"
                  style={{ rotate: inverseRotate, scale: 1.725 }}
                />
              </li>
            ))}
          </MotionGrid>
        </Centered>
      </ScrollContainer>
    </>
  )
}

export default Showcase
