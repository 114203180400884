import styled from 'styled-components'

const itemAreas = [...Array(10)].reduce(
  (acc, _, i) =>
    acc +
    `&:nth-child(${i}) {
        grid-area: a${i};
      }
      `,
  ''
)

export const ScrollContainer = styled.div`
  height: 400vh;
`

export const Centered = styled.div`
  position: fixed;
  inset: 0;
  overflow: hidden;
`

export const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  width: 50vw;
  height: 50vw;
  gap: 1rem;
  margin: 0 auto;
  grid-template-areas:
    '.  a8 a8 a8 a8  .  .  .  .'
    '.  a8 a8 a8 a8 a1 a1  .  .'
    '.  a8 a8 a8 a8 a1 a1  .  .'
    'a2 a2 a2 a3 a3 a3 a4 a4  .'
    'a2 a2 a2 a3 a3 a3 a4 a4  .'
    'a2 a2 a2 a3 a3 a3 a5 a5 a5'
    '.  a6 a6 a7 a7 a7 a5 a5 a5'
    '.  a6 a6 a7 a7 a7 a5 a5 a5'
    '.  .   . a7 a7 a7  .  .  .'
    '.  .   . a7 a7 a7  .  .  .';

  list-style: none;
  transform-origin: center;

  & > li {
    overflow: hidden;
    border-radius: 1rem;
    transform: translateZ(0);

    & > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    ${itemAreas}
  }
`
