import React from 'react'
import Spinner from './spinner'
import { Overlay, GlobalStyle } from './styled'

const LoadingScreen = () => {
  return (
    <>
      <GlobalStyle />
      <Overlay>
        <Spinner />
      </Overlay>
    </>
  )
}

export default LoadingScreen
