import { useState, useEffect } from 'react'
import imagesLoaded from 'imagesloaded'

const usePreloadImages = (selector: string = 'img') => {
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    imagesLoaded(selector, { background: true }, () => {
      setLoading(false)
    })
  }, [])

  return isLoading
}

export default usePreloadImages
